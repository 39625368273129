import React, { Fragment, useEffect, useState } from "react";
import "./photogallery.css";
import PopupImageSliderLightbox from "../popup-image-slider-lightbox/PopupImageSliderLightbox";
import { api } from "../../helper/api";
import Loading from "../Loading/Loading";

const PhotoGallery = () => {
	const [openImageSlider, setOpenImageSlider] = useState(false);
	const [photosArray, setPhotosArray] = useState();
	const [photosData, setPhotosData] = useState();
	const [loading, setLoading] = useState(true);
	const [expandedDescriptions, setExpandedDescriptions] = useState({});

	const openIMageSliderLightbox = (photosData) => {
		setPhotosArray(photosData);
		return setOpenImageSlider(true);
	};

	const toggleDescription = (index) => {
		setExpandedDescriptions((prev) => ({
			...prev,
			[index]: !prev[index],
		}));
	};

	useEffect(() => {
		const loadMaterial = async () => {
			try {
				const response = await api({
					method: "post",
					url: "fetch.php",
					data: { fetch_all_photoGallery: true },
					headers: { "Content-Type": "multipart/form-data" },
				});
				const jsonData = await response.data;
				setPhotosData(jsonData.data);
				setLoading(false);
			} catch (err) {
				setLoading(false);
			}
		};
		loadMaterial();
	}, []);

	return (
		<Fragment>
			{loading && <Loading />}
			<div className="photo-gallery-page-container container">
				<div className="photo-gallery-page-card-container">
					{photosData && photosData.length > 0
						? photosData.map((item, index) => {
							const isExpanded = expandedDescriptions[index];
							const truncatedDescription =
								item.event_desp?.length > 100
									? `${item.event_desp.substring(0, 350)}...`
									: item.event_desp;
							return (
								<div
									className="photo-gallery-page-single-card"
									key={index}
								>
									<div className="photo-gallery-event-details">
										<p className="event-date">
											{item.event_date}
										</p>
										<h4 className="event-title">
											{item.event_name}
										</h4>
										{item.event_desp && (
											<p
												className="event-description"
											>
												<span
													dangerouslySetInnerHTML={{
														__html: isExpanded ? item.event_desp : truncatedDescription,
													}}
												></span>
												<span
													className="read-more-less"
													onClick={() => toggleDescription(index)}
													style={{
														color: "red",
														// fontWeight: "bold",
														cursor: "pointer",
													}}
												>
													{isExpanded ? " Read Less" : " Read More"}
												</span>
											</p>
										)}
									</div>
									<div className="photo-gallery-page-images-container">
										{item.photo_array.map((images, index) => {
											return (
												<Fragment>
													{index >= 3 ? (
														""
													) : (
														<img
															src={images.src}
															alt="Gallery Image"
														/>
													)}
												</Fragment>
											);
										})}
									</div>
									{item.photo_array.length > 3 && (
										<div className="photo-gallery-page-more-image">
											<button
												className="photo-gallery-page-more-image-button"
												onClick={() => {
													openIMageSliderLightbox(item.photo_array);
												}}
											>
												View More
											</button>
										</div>
									)}
								</div>
							);
						})
						: "No Photo Added"}
				</div>
			</div>
			{openImageSlider ? (
				<PopupImageSliderLightbox
					isOpen={openImageSlider}
					photo_array={photosArray}
					onHide={() => {
						setOpenImageSlider(false);
					}}
				/>
			) : (
				""
			)}
		</Fragment>
	);
};

export default PhotoGallery;
